import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  Radio,
  DatePicker,
  Button,
  message,
  Card,
} from 'antd';
import dtService from '../../services/dtService';
import BuscadorPorHash from './BuscadorPorHash';
import { SearchOutlined } from '@ant-design/icons';
import CosultaResolucion from '../../utils/CosultaResolucion';
import FormatDateV2 from '../../utils/FormatDateV2';
import locale from 'antd/es/date-picker/locale/es_ES';
import useAuth from '../../hooks/useAuth';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function BuscadorReportes({
  setFirstTable,
  tipoBuscador = 'asistencia',
  jobsAreLoading,
  setJobsAreLoading,
  setDatosSeleccionados,
  setexportarExcel,
  setexportarPdf,
  setexportarWord,
}) {
  // ajuste buscador de semana
  const { empresaID } = useAuth();
  const hoy = new Date();
  const semana = new Date();
  semana.setDate(semana.getDate() - 60);
  const [form] = Form.useForm();
  const [desde, setDesde] = useState(semana.toISOString().split('T')[0]);
  const [hasta, setHasta] = useState(hoy.toISOString().split('T')[0]);
  const [cargos, setCargos] = useState([]);
  const [ubicaciones, setUbicaciones] = useState([]);

  const [ubicacionesFiltradas, setUbicacionesFiltradas] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [disableInput, setDisableInput] = useState(false);
  const [correos, setCorreos] = useState([]);
  const [loadingSelect, setLoadingSelect] = useState(true);
  const [trabajadores, setTrabajadores] = useState([]);
  const [jornadas, setJornadas] = useState([]);
  const [turnos, setTurnos] = useState([]);
  const [regiones, setRegiones] = useState([]);
  const [isEst, setIsEst] = useState([]);
  /* const [hashTable, setHashTable] = useState([]);
  const [hashAreLoading, setHashAreLoading] = useState(true); */
  const fechas = (event) => {
    const dates = event.map((item) => {
      const fecha = dayjs(new Date(item)).format('YYYY-MM-DD');
      return fecha;
    });
    const desde = dates[0];
    const hasta = dates[1];
    setDesde(desde);
    setHasta(hasta);
  };

  const resetFilters = () => {
    form.resetFields();
    setDisableInput(false);
  };

  const onChange = (e) => {
    setValue(e.target.value);
    if (e.target.value === 0) {
      form.resetFields(['fecha']);
      const hoy = new Date();
      const semana = new Date();
      semana.setDate(semana.getDate() - 60);
      const desde = semana.toISOString().split('T')[0];
      const hasta = hoy.toISOString().split('T')[0];
      // console.log('if', desde, hasta);
      setDesde(desde);
      setHasta(hasta);
    }
    if (e.target.value === 1) {
      const hoy = new Date();
      const semana = new Date();
      semana.setDate(semana.getDate() - 7);
      const desde = semana.toISOString().split('T')[0];
      const hasta = hoy.toISOString().split('T')[0];
      setDesde(desde);
      setHasta(hasta);
    }
    if (e.target.value === 2) {
      const hoy = new Date();
      const semanas = new Date();
      semanas.setDate(semanas.getDate() - 14);
      const desde = semanas.toISOString().split('T')[0];
      const hasta = hoy.toISOString().split('T')[0];
      setDesde(desde);
      setHasta(hasta);
    }
    if (e.target.value === 3) {
      const hoy = new Date();
      const mes = new Date();
      mes.setDate(mes.getDate() - 30);
      const desde = mes.toISOString().split('T')[0];
      const hasta = hoy.toISOString().split('T')[0];
      setDesde(desde);
      setHasta(hasta);
    }
    if (e.target.value === 4) {
      const hoy = new Date();
      const meses = new Date();
      meses.setDate(meses.getDate() - 365);
      const desde = meses.toISOString().split('T')[0];
      const hasta = hoy.toISOString().split('T')[0];
      setDesde(desde);
      setHasta(hasta);
    }
  };

  const ajusteTurnosSelector = (array) => {
    const arraySelectorTurno = [];
    if (array.length !== 0) {
      array.forEach((obj) => {
        const nexElement = {
          label: `${obj.turnos.diasSemana} ${dayjs(obj.turnos.fechaHoraInicio)
            .tz('America/Santiago')
            .format('HH:mm:ss')} - ${dayjs(obj.turnos.fechaHoraFin)
            .tz('America/Santiago')
            .format('HH:mm:ss')}`,
          value: obj.turnos.id,
        };

        arraySelectorTurno.push(nexElement);
      });

      // console.log(array);
      // console.log(arraySelectorJornada);
      setTurnos(arraySelectorTurno);
    }
  };

  const handleRegionChange = (value) => {
    if (value) {
      const region = value.trim();
      const ubicacionActual = ubicaciones.filter((ubicacion) => {
        const regionActual = ubicacion.split(',')[1].trim();

        if (regionActual === region) {
          return true;
        }
        return false;
      });

      if (!ubicacionActual.length === 0) {
        setUbicacionesFiltradas(ubicaciones);
      } else {
        setUbicacionesFiltradas(ubicacionActual);
      }
    }
  };

  const handleSelectChange = (value) => {
    setCorreos(value);
    if (value.length === 1) {
      const lista = trabajadores.filter((item) => item.dni === value[0]);
      const objetoNombre = lista[0];
      setDisableInput(true);
      form.setFieldsValue({
        rut: objetoNombre.dni,
        nombres: objetoNombre.nombre,
        primerApellido: objetoNombre.primerApellido,
        segundoApellido: objetoNombre.segundoApellido,
      });
    } else if (value.length > 1) {
      form.resetFields(['rut', 'nombres', 'primerApellido', 'segundoApellido']);
      setDisableInput(true);
    } else {
      form.resetFields();
      setDisableInput(false);
    }
  };

  async function buscarTrabajadores(
    nombres,
    primerApellido,
    segundoApellido,
    values,
    correosValue,
    tipoBucacdor
  ) {
    try {
      const [res, err] = await dtService.BuscarTrabajadores(
        tipoBuscador,
        empresaID?.empresaId || '',
        nombres,
        primerApellido,
        segundoApellido,
        values?.rut || '',
        desde,
        hasta,
        values?.ubicacion || '',
        values?.cargo.toString() || '',
        correosValue,
        tipoBucacdor,
        values?.jornada || '',
        values?.turno || '',
        values?.est || ''
      );

      if (!err) {
        const resFilter = res.data.filter((item) => item.reporte.length !== 0);
        // console.log(res);

        if (tipoBuscador === 'festivos') {
          res.data.desde = desde;
          res.data.hasta = hasta;
          // console.log(res.data);
          setFirstTable(res.data);
          setDatosSeleccionados(res.data);
        } else {
          resFilter.desde = desde;
          resFilter.hasta = hasta;
          setFirstTable(resFilter);
          setDatosSeleccionados(resFilter);
        }

        if (res.data.length === 0) {
          message.error('No se encontraron resultados');
          setJobsAreLoading(true);
          setButtonLoading(false);
        }
        setJobsAreLoading(false);
        setButtonLoading(false);
      } else {
        message.error('Favor verificar los datos ingresados');
        setJobsAreLoading(true);
        setButtonLoading(false);
      }
    } catch (error) {
      console.error('Error al buscar trabajadores:', error);
      setJobsAreLoading(true);
      setButtonLoading(false);
    }
  }
  const handleSubmit = async (values) => {
    setButtonLoading(true);
    setJobsAreLoading(true);

    let correosValue = '';
    let tipoBucacdor = 'particular';
    if (disableInput) {
      correosValue = correos.toString();
      tipoBucacdor = 'masiva';
    }

    if (values.cargo === undefined) {
      values.cargo = [];
    }

    function primeraLetraMayuscula(str) {
      let string = str.toLowerCase();
      string = string.charAt(0).toUpperCase() + string.slice(1);
      return string;
    }

    if (values.primerApellido) {
      values.primerApellido = primeraLetraMayuscula(values.primerApellido);
    }
    if (values.segundoApellido) {
      values.segundoApellido = primeraLetraMayuscula(values.segundoApellido);
    }

    if (values.nombres) {
      buscarTrabajadores(
        primeraLetraMayuscula(values.nombres),
        values.primerApellido,
        values.segundoApellido,
        values,
        correosValue,
        tipoBucacdor
      );
    } else if (values.primerApellido || values.segundoApellido) {
      message.error('Ingrese el nombre del Trabajador');
    } else {
      buscarTrabajadores(
        values.nombres,
        values.primerApellido,
        values.segundoApellido,
        values,
        correosValue,
        tipoBucacdor
      );
    }
  };

  const Verobjetos = async () => {
    const [res, err] = await dtService.consultarinformacionBuscador(
      'cliente',
      empresaID?.empresaId || ''
    );

    if (!err) {
      // console.log(res.data);
      setCargos(res.data.cargos || []);

      setUbicaciones(res.data.ubicaciones || []);
      setUbicacionesFiltradas(res.data.ubicaciones || []);
      setTrabajadores(res.data.trabajadores || []);
      //  ajusteItemSelector(res.data.Jornadas || []);
      setJornadas(res.data.Jornadas || []);
      ajusteTurnosSelector(res.data.turnos || []);

      setRegiones(res.data.regiones || []);
      // setTurnos(res.data.turnos || []);

      setLoadingSelect(false);
    } else {
      console.log(err);
    }
  };
  const VerEmpresas = async () => {
    const [res, err] = await dtService.consultarinformacionBuscador('dt');
    if (!err) {
      const responseEmpresas = res?.data?.empresas;

      const empresasEst = responseEmpresas.filter((empresa) => {
        if (empresa.est) {
          return true;
        }
        return false;
      });

      setIsEst(empresasEst);
    } else {
      console.log(err);
    }
  };

  useEffect(() => {
    Verobjetos();
    VerEmpresas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card
      title="Selecciona el trabajador que deseas consultar"
      extra={<Button onClick={() => resetFilters()}>Limpiar buscador</Button>}
      style={{ marginBottom: '10px' }}>
      <Form
        form={form}
        layout="horizontal"
        requiredMark={false}
        onFinish={handleSubmit}
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 19,
        }}>
        <Card
          title={<strong>Periodo o Rango de Fechas</strong>}
          style={{
            marginBottom: '20px',
          }}
          headStyle={{ textAlign: 'center' }}>
          <Form.Item name="periodo" label="Periodo">
            <Radio.Group onChange={onChange} value={value} defaultValue={0}>
              <Radio value={0}>Seleccionar fecha</Radio>
              <Radio value={1}>Última Semana</Radio>
              <Radio value={2}>Última Quincena</Radio>
              <Radio value={3}>Último Mes</Radio>
              {tipoBuscador === 'festivos' && (
                <Radio value={4}>Últimos 12 meses</Radio>
              )}
            </Radio.Group>
            <p style={{ marginTop: '5px' }}>
              Al no seleccionar una fecha la busqueda se hara por los dos
              ultimos dos meses.
            </p>
          </Form.Item>

          <Form.Item
            name="fecha"
            label="Fecha"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <DatePicker.RangePicker
              id="fecha"
              name="fecha"
              style={{ width: '100%' }}
              placeholder={['Fecha inicio', 'Fecha fin']}
              onChange={fechas}
              disabled={value !== 0}
              allowClear={true}
              format={'DD/MM/YY'}
              locale={locale}
            />
          </Form.Item>
        </Card>

        <Card
          title={<strong>Búsqueda individual</strong>}
          headStyle={{ textAlign: 'center' }}
          style={{ marginBottom: '20px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Form.Item
              hidden={!CosultaResolucion(window.outerWidth) || false}
              name="nombres"
              label="Nombres">
              <Input
                disabled={disableInput}
                id="nombres"
                name="nombres"
                type="text"
                placeholder="Nombres"
              />
            </Form.Item>

            <Form.Item
              hidden={!CosultaResolucion(window.outerWidth) || false}
              name="primerApellido"
              label="Primer Apellido">
              <Input
                disabled={disableInput}
                id="primerApellido"
                name="primerApellido"
                type="text"
                placeholder="Primer apellido"
              />
            </Form.Item>

            <Form.Item
              hidden={!CosultaResolucion(window.outerWidth) || false}
              name="segundoApellido"
              label="Segundo Apellido">
              <Input
                disabled={disableInput}
                id="segundoApellido"
                name="segundoApellido"
                type="text"
                placeholder="Segundo apellido"
              />
            </Form.Item>

            <Form.Item
              hidden={CosultaResolucion(window.outerWidth) || false}
              name="nombreCompleto"
              label="Nombre"
              style={{ marginBottom: 0 }}>
              <Form.Item
                name="nombres"
                style={{
                  display: 'inline-block',
                  width: 'calc(40% - 8px)',
                }}>
                <Input
                  disabled={disableInput}
                  id="nombres"
                  name="nombres"
                  type="text"
                  placeholder="Nombres"
                />
              </Form.Item>
              <Form.Item
                name="primerApellido"
                style={{
                  display: 'inline-block',
                  width: 'calc(30% - 8px)',
                  margin: '0 8px',
                }}>
                <Input
                  disabled={disableInput}
                  id="primerApellido"
                  name="primerApellido"
                  type="text"
                  placeholder="Primer apellido"
                />
              </Form.Item>
              <Form.Item
                disabled={disableInput}
                name="segundoApellido"
                style={{
                  display: 'inline-block',
                  width: 'calc(30%)',
                }}>
                <Input
                  disabled={disableInput}
                  id="segundoApellido"
                  name="segundoApellido"
                  type="text"
                  placeholder="Segundo apellido"
                />
              </Form.Item>
            </Form.Item>

            <Form.Item name="rut" label="RUT">
              <Input
                id="rut"
                name="rut"
                type="text"
                placeholder="Ej: 12345678-9"
                disabled={disableInput}
              />
            </Form.Item>
          </div>
        </Card>

        <Card
          title={<strong>Búsqueda grupal</strong>}
          style={{
            marginBottom: '20px',
          }}
          headStyle={{ textAlign: 'center' }}>
          <div align="center">
            <Form.Item
              name="Trabajador"
              label="Lista de Trabajadores"
              style={{ width: '80%' }}>
              <Select
                style={{ width: '80%' }}
                showSearch
                placeholder="Seleccione los trabajadores"
                mode="multiple"
                loading={loadingSelect}
                allowClear={true}
                onChange={handleSelectChange}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={trabajadores.map((trabajador) => ({
                  label: trabajador.nombre,
                  // label: `${trabajador.nombre} ${trabajador.primerApellido} ${trabajador.segundoApellido}_${trabajador.dni}`,
                  value: trabajador.correo,
                  name: 'Trabajador',
                }))}
              />
            </Form.Item>
            <Form.Item name="cargo" label="Cargo" style={{ width: '80%' }}>
              <Select
                style={{ width: '80%' }}
                showSearch
                placeholder="Selecciona un cargo"
                mode="multiple"
                allowClear={true}
                loading={loadingSelect}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={cargos.map((cargo) => ({
                  label: cargo,
                  value: cargo,
                }))}
                onClick={() => {
                  if (cargos.length === 0) {
                    setJobsAreLoading(true);
                    // getCargos();
                  }
                }}
                onChange={(value) => {
                  console.log(`ID del cargo ${value}`);
                }}
              />
            </Form.Item>
            <Form.Item name="jornada" label="Jornada" style={{ width: '80%' }}>
              <Select
                style={{ width: '80%' }}
                showSearch
                placeholder="Selecciona una jornada"
                allowClear={true}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={jornadas.map((jornada) => ({
                  label: jornada,
                  value: jornada,
                }))}
              />
            </Form.Item>
            <Form.Item name="turno" label="Turnos" style={{ width: '80%' }}>
              <Select
                style={{ width: '80%' }}
                showSearch
                placeholder="Selecciona un Turno"
                allowClear={true}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={turnos.map((turno) => ({
                  label: turno.label,
                  value: turno.value,
                }))}
              />
            </Form.Item>
            <Form.Item name="region" label="Región" style={{ width: '80%' }}>
              <Select
                style={{ width: '80%' }}
                showSearch
                placeholder="Selecciona una región"
                allowClear={true}
                loading={loadingSelect}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={regiones.map((region) => ({
                  label: region,
                  value: region,
                }))}
                onChange={handleRegionChange}
              />
            </Form.Item>
            <Form.Item name="ubicacion" label="Local:" style={{ width: '80%' }}>
              <Select
                style={{ width: '80%' }}
                showSearch
                placeholder="Selecciona una ubicacion"
                allowClear={true}
                loading={loadingSelect}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={ubicacionesFiltradas.map((ubicacion) => ({
                  label: ubicacion,
                  value: ubicacion,
                }))}
                onClick={() => {
                  if (ubicaciones.length === 0) {
                    setJobsAreLoading(true);
                    // getUbicaciones();
                  }
                }}
                onChange={(value) => {}}
              />
            </Form.Item>
            <Form.Item
              name="est"
              label="EST, Subcontratista:"
              style={{ width: '80%' }}>
              <Select
                style={{ width: '80%' }}
                showSearch
                placeholder="Seleccione una Empresa Est/Subcontratista"
                allowClear={true}
                loading={loadingSelect}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={isEst.map((est) => ({
                  label: `${est.razonSocial} - ${est.rut}`,
                  value: est.rut,
                }))}
                onClick={() => {
                  if (isEst.length === 0) {
                    setJobsAreLoading(true);
                    // getUbicaciones();
                  }
                }}
              />
            </Form.Item>
          </div>
        </Card>
        <Card
          title="Ingrese el hash que desea validar y consultar"
          style={{ marginBottom: '10px', maxWidth: '500px' }}>
          <Form
            form={form}
            layout="horizontal"
            requiredMark={false}
            onFinish={handleSubmit}
            labelCol={{
              span: 6, // Aumentado el span para dar más espacio al label
            }}
            wrapperCol={{
              span: 18, // Aumentado el span para dar más espacio al input
            }}>
            <Card
              title="Validación de Hash"
              headStyle={{ textAlign: 'center' }}
              style={{ marginBottom: '20px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Form.Item
                  name="hash"
                  label="Hash"
                  style={{ width: '70%', marginRight: '10px' }}>
                  <Input
                    id="hash"
                    name="hash"
                    type="text"
                    placeholder="hash"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Button
                  type="primary"
                  //  htmlType="submit"
                  icon={<SearchOutlined />}
                  loading={buttonLoading}>
                  Validar Hash
                </Button>
              </div>
            </Card>
          </Form>
        </Card>

        <div className="flex justify-end">
          <Button
            block
            style={{ marginBottom: '20px' }}
            type="primary"
            htmlType="submit"
            className="mr-2"
            icon={<SearchOutlined />}
            loading={buttonLoading}>
            Generar Reporte
          </Button>
        </div>
      </Form>
    </Card>
  );
}
